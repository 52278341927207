@import url(../../node_modules/@evertrue/et-components/dist/styles.css);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body,
html {
  margin: 0;
  padding: 0;
  height: 100%; }

body {
  background: #f5f5f5;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  color: #454545;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden; }

button {
  font-size: 14px;
  cursor: pointer; }

a {
  color: #19a4df;
  text-decoration: none; }
  a:hover {
    color: #1482b1; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.25em;
  text-rendering: optimizeLegibility;
  font-weight: normal; }

h2 {
  font-size: 28.5px; }

h3 {
  font-size: 24.5px; }

strong {
  font-weight: bold; }

input[type="text"],
input[type="password"] {
  appearance: none;
  border: solid 1px #d2dde0;
  border-bottom: solid 2px #d2dde0;
  border-radius: 2px;
  padding: 8px 16px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
  color: #454545;
  width: 100%;
  height: 40px; }
  input[type="text"]:focus:not(:disabled), input[type="text"]:hover:not(:disabled),
  input[type="password"]:focus:not(:disabled),
  input[type="password"]:hover:not(:disabled) {
    border: solid 1px #c3d2d6;
    border-bottom: solid 2px #c3d2d6; }
  input[type="text"]:disabled,
  input[type="password"]:disabled {
    background: #fff; }
  input[type="text"]::-ms-clear,
  input[type="password"]::-ms-clear {
    display: none; }
  input[type="text"]::-webkit-search-cancel-button,
  input[type="password"]::-webkit-search-cancel-button {
    display: none; }

ul {
  list-style: none;
  padding: 0; }

.et--alert-controller {
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 10; }

.custom-fields-table--draggable {
  border-bottom: 1px solid #d2dde0; }

.et--modal--overlay {
  z-index: 9; }

.et--actions-menu--menu-option {
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif; }

.et--flex-table--sort-header-cell {
  text-align: left; }
  .et--flex-table--sort-header-cell:hover {
    background: none; }

.et--flex-table--header-row {
  background-color: #4d8899;
  min-height: 50px;
  color: #6a909a;
  border-top: solid 1px #d2dde0;
  border-bottom: solid 1px #d2dde0; }

.et--flex-table--header-cell {
  padding: 16px 0 16px 16px;
  z-index: auto; }

.et--flex-table--header-cell,
.et--flex-table--cell {
  justify-content: flex-start;
  align-items: center; }

.et--flex-table--cell {
  background-color: #fff;
  padding: 16px 0 16px 16px; }

.importer-layout {
  width: 100%; }

.import-table--pagination {
  margin: 15px; }

.selected-row {
  background-color: #fcf8fc; }

.days-description {
  flex: 0 1 auto;
  margin-right: 10px;
  margin-left: 15px;
  margin-top: 35px;
  color: #FF033E;
  white-space: normal;
  line-height: 1.2; }

body {
  margin: 0;
  padding: 0; }

p {
  margin: 0; }

#root {
  height: 100%; }

strong {
  font-weight: 500; }
